<template>
  <div class="inn__wrapper">
    <iq-title class-name="mobileHeader">
      Обязательное указание ИНН поставщика:
    </iq-title>
    <div>
      <div class="inn">
        <el-checkbox v-model="currentState">
          {{ currentState ? 'Отключить' : 'Сделать обязательным' }}
        </el-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import IqTitle from '@/views/ui/components/typography/IqTitle'

export default {
  name: 'RequireInn',
  components: { IqTitle },
  props: {
    requireInn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentState: {
      get() {
        return this.requireInn
      },
      set(value) {
        this.$emit('setRequireInn', value)
      },
    },
  },
}
</script>

<style lang="sass" scoped>
.inn
  .el-checkbox
    display: block
    margin-bottom: 20px
    :deep(.el-checkbox__inner)
      width: 21px
      height: 21px
      &::after
        border: 3px solid #FFF
        border-left: 0
        border-top: 0
        height: 11px
        left: 7px
</style>
