var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "limits__wrapper" },
    [
      _c("iq-title", { attrs: { "class-name": "mobileHeader" } }, [
        _vm._v("Лимит ручных тайм слотов:"),
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "limits" },
          [
            _c(
              "el-checkbox",
              {
                model: {
                  value: _vm.limitState,
                  callback: function ($$v) {
                    _vm.limitState = $$v
                  },
                  expression: "limitState",
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.limitState ? "Включен" : "Включить") + " "
                ),
              ]
            ),
            _vm.limitState
              ? _c("el-input-number", {
                  attrs: { min: 1, max: 1000 },
                  model: {
                    value: _vm.limitSize,
                    callback: function ($$v) {
                      _vm.limitSize = $$v
                    },
                    expression: "limitSize",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }