var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("iq-title", { attrs: { "class-name": "mobileHeader" } }, [
        _vm._v("Размер буфера:"),
      ]),
      _c("div", { staticClass: "settings__main-section" }, [
        _c(
          "div",
          { staticStyle: { flex: "1" } },
          [
            _c("el-input-number", {
              attrs: { min: 1, max: 15 },
              model: {
                value: _vm.bufferSize,
                callback: function ($$v) {
                  _vm.bufferSize = $$v
                },
                expression: "bufferSize",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }