var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inn__wrapper" },
    [
      _c("iq-title", { attrs: { "class-name": "mobileHeader" } }, [
        _vm._v(" Обязательное указание ИНН поставщика: "),
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "inn" },
          [
            _c(
              "el-checkbox",
              {
                model: {
                  value: _vm.currentState,
                  callback: function ($$v) {
                    _vm.currentState = $$v
                  },
                  expression: "currentState",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.currentState ? "Отключить" : "Сделать обязательным"
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }