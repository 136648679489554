import { render, staticRenderFns } from "./LimitSize.vue?vue&type=template&id=3b9b3476&"
import script from "./LimitSize.vue?vue&type=script&lang=js&"
export * from "./LimitSize.vue?vue&type=script&lang=js&"
import style0 from "./LimitSize.vue?vue&type=style&index=0&id=3b9b3476&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3b9b3476')) {
      api.createRecord('3b9b3476', component.options)
    } else {
      api.reload('3b9b3476', component.options)
    }
    module.hot.accept("./LimitSize.vue?vue&type=template&id=3b9b3476&", function () {
      api.rerender('3b9b3476', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/settings/components/LimitSize.vue"
export default component.exports